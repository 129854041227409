<template>
  <div class="arrow-hover">
    <div></div>
  </div>
  <div
    v-if="(route.path !== '/company' && route.name !== 'main') || isAnalyticsPage"
    class="back-btn"
    @click="goToPrevPage"
  >
    <i class="fal fa-arrow-left"></i>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "back-page-btn",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
		const isAnalyticsPage = computed(() => route.name === 'analytics-report')
		const goToPrevPage = () => {
				router.go(-1);
		}

    return {
      route,
			goToPrevPage,
			isAnalyticsPage
    };
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  background: linear-gradient(to right, #eceeef, rgba(255, 255, 255, 0.5));
  width: 40px;
  height: 40px;
  padding: 2px;
  border-radius: 12px;

  i {
    font-size: 20px;
    color: #3D3D3D;
  }
  i:hover {
    animation: i-hover 10s ease-in-out infinite;
  }
  @keyframes i-hover {
    0% {
      right:0;
      width: 200%
    }
    50% {
      right:10px;
      width: 150%
    }
    100% {
      right:0;
      width: 100%
    }
  }

  @media (max-width: 550px) {
    display: none;
  }


}
</style>