import { createRouter, createWebHistory } from "vue-router";


const routes = [
    {
        path: "/",
        name: "main",
        component: () => import('./../views/Home/Home.vue')
    },
    {
        path: "/staff",
        name: "staff",
        component: () => import('./../views/Staff/Staff.vue')
    },
    {
        path: "/staff/:id?/edit/:group_id?",
        name: "staff-page",
        component: () => import("../feature/users/StaffForm.vue")
    },
    {
        path: "/curator",
        name: "curator",
        component: () => import('./../views/Curator/Curator.vue')
    },
    {
        path: "/company/:id?",
        alias: ["/company/create"],
        name: "company",
        component: () => import('./../views/Company/Company.vue')
    },
    {
        path: "/organizations",
        name: "organizations",
        component: () => import('./../views/Company/Organizations.vue')
    },
    {
        path: "/group",
        name: "group",
        component: () => import('./../views/Group/Groups.vue')
    },
    {
        path: "/group/edit/:id?",
        name: "group-page",
        component: () => import("../feature/group-control/forms/GroupForm.vue")
    },
    {
        path: "/group/:id",
        name: "single-group",
        component: () => import("../feature/group-control/tables/GroupPassingsTable.vue")
    },
    {
        path: "/test/question",
        name: "question-page",
        component: () => import("./../views/Test/Question.vue")
    },
    {
        path: "/test/question-type",
        name: "questiontype-page",
        component: () => import("./../views/Test/QuestionType.vue")
    },
    {
        path: "/test/question-group",
        name: "questiongroup-page",
        component: () => import("./../views/Test/QuestionGroup.vue")
    },
    {
        path: "/test/question/:id?/edit",
        name: "question-edit",
        component: () => import("../feature/testing/forms/QuestionForm.vue")
    },
    {
        path: "/test/question-type/:id?/edit",
        name: "question-type-edit",
        component: () => import("../feature/testing/forms/QuestionTypeForm.vue")
    },
    {
        path: "/test/question-group/:id?/edit",
        name: "question-group-edit",
        component: () => import("../feature/testing/forms/QuestionGroupForm.vue")
    },
    {
        path: "/test/passing",
        name: "passings",
        component: () => import("../views/Test/Passing.vue")
    },
    {
        path: "/test/passing/:id/edit",
        name: "pssing-edit",
        component: () => import("../feature/testing/forms/PassingEditForm")
    },
    {
        path: "/test/passing/create",
        name: "passing-create",
        component: () => import("../feature/testing/forms/PassingCreateForm")
    },
    {
        path: "/passing/:code",
        name: "testing",
        component: () => import("../views/Testing/Testing.vue")
    },
    {
        path: "/passing",
        name: "not-testing",
        component: () => import("../views/Testing/NotTestingPage.vue")
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("../views/Profile/Profile.vue")
    },
    {
        path: "/analytics",
        name: "analytics",
        component: () => import("../views/Analytics/AnalyticsForm.vue")
    },
    {
        path: "/analytics/:name",
        name: "analytics-report",
        component: () => import("../views/Analytics/AnalyticsReport.vue")
    },
    {
        path: "/excel",
        name: "excel",
        component: () => import("../views/Staff/ExcelStaff.vue")
    },
    {
        path: "/analytics/references",
        name: "analytic-references",
        component: () => import("../views/Analytics/ReferenceProfiles.vue")
    },
    {
        path: "/relations",
        name: "relations",
        component: () => import("../views/Relations/Relations.vue")
    },
    {
        path: "/orders",
        name: "orders",
        component: () => import("../views/Orders/Orders.vue")
    },
    {
        path: "/orders/create",
        name: "orders-create",
        component: () => import("../views/Orders/CreateOrders.vue")
    },
    {
        path: "/notifications/create",
        name: "notifications-create",
        component: () => import("../views/Notifications/CreateNotification.vue")
    },
    {
        path: "/notifications/options",
        name: "notifications-options",
        component: () => import("../views/Notifications/NotificationsOptions.vue")
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("../views/FAQ/FAQ.vue")
    },
    {
        path: "/partners",
        name: "partners",
        component: () => import("../views/Partners/Partners.vue")
    },
    {
        path: "/experts",
        name: "experts",
        component: () => import("../views/Partners/Experts.vue")
    },
    {
        path: "/reviews",
        name: "reviews",
        component: () => import("../views/Partners/Reviews.vue")
    },
    {
        path: "/partner/:id?",
        name: "partner",
        alias: ["/partner/create"],
        component: () => import("../views/Partners/PartnerCenter.vue")
    },
    {
        path: "/expert/:id?",
        name: "expert",
        alias: ["/expert/create"],
        component: () => import("../views/Partners/PartnerExpert.vue")
    },
    {
        path: "/review/:id?",
        name: "review",
        alias: ["/review/create"],
        component: () => import("../views/Partners/Review.vue")
    },
    {
        path: "/begin",
        name: "begin",
        component: () => import("../views/BeginWork/BeginWork.vue")
    },
    {
        path: "/references/files",
        name: "references-files",
        component: () => import("../views/References/ReferencesFiles.vue")
    },
    {
        path: "/references/profiles",
        name: "references-list",
        component: () => import("../views/References/ReferencesList.vue")
    },
    {
        path: "/excel/references",
        name: "excel-references",
        component: () => import("../views/References/ExcelReferences.vue")
    },
    {
        path: "/profiles/report/:id?",
        name: "profiles-report",
        component: () => import("../views/Report/ProfileReport.vue")
    },
    {
        path: "/:product/:id/:nameProduct?",
        name: "product-report",
        component: () => import("../views/ProductGroupReport/ProductGroupReport.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
